<template>
    <v-snackbar
      bottom
      center
      :color="notification.color"
      :timeout="notification.timeout"
      v-model="snackbar"
    >
      {{notification.message}}
      <v-spacer></v-spacer>
      <v-btn icon :dark="notification.dark" @click="navega(notification.url)">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn icon :dark="notification.dark" @click="snackbar=false">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-snackbar>
</template>

<script>
import bus from "../bus";
    export default {
        name: "Notifications",
        data:() => ({
            snackbar: false,
            notification: { color: "grey" },
            notifications: [
            "Mike, John responded to your email",
            "You have 5 new tasks",
            "You're now a friend with Andrew",
            "Another Notification",
            "Another One"
            ],
            audio: new Audio("sounds/Notificacao.mp3"),
        }),
        methods:{
            navega(url) {
            if (url.includes("://")) window.open(url);
            else this.$router.push(url).catch(err => {});
            },
        },
        mounted(){
            bus.$on("notification", args => {
                //console.log("NOTIFICATION", args);
                this.snackbar = true;
                if (args != null)
                    this.notification = args;
                this.audio.play().catch(() => {});
            });
            bus.$on("dismiss_notification", () => {
                this.snackbar = false;
            });

            bus.$emit("notification", {
                color: "grey",
                url: "http://www.google.com.br/search?q=tempo",
                message: `Olá ${JSON.parse(sessionStorage.user).user.nome}`,
                timeout: 3000
            });
        },
        beforeDestroy() {
            bus.$off("notification");
            bus.$off("dismiss_notification");
        }
    }
</script>

<style lang="css" scoped>

</style>